import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AppStore from "../../images/app-store.svg"
import PlayStore from "../../images/play-store.svg"
import MagnusLogo from "../../images/magnus-logo.png"
import ToolboxLogo from "../../images/toolbox-app-logo.png"
import "../../styles/apps.sass"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Apps" />
      <div className="container">
        <div className="apps">
          <div className="app app-1">
            <div className="app-title">
              <img src={MagnusLogo} className="app-logo" />
              Magnus
            </div>
            <div className="options">
              <div className="option">
                <a
                  href="https://apps.apple.com/app/id1485215538"
                  target="_blank"
                >
                  <img src={AppStore} className="store-img" />
                </a>
              </div>
              <div className="option">
                <a
                  href="https://play.google.com/store/apps/details?id=com.magnusmonitors.user"
                  target="_blank"
                >
                  <img src={PlayStore} className="store-img" />
                </a>
              </div>
            </div>
            <div>
              <Link to="/apps/magnus/account-delete">Click Here</Link> to delete
              your Magnus account.
            </div>
          </div>
          <div className="app app-2">
            <div className="app-title">
              <img src={ToolboxLogo} className="app-logo" />
              Toolbox for Magnus
            </div>
            <div className="options">
              <div className="option">
                <a
                  href="https://apps.apple.com/app/id1549492513"
                  target="_blank"
                >
                  <img src={AppStore} className="store-img" />
                </a>
              </div>
              <div className="option">
                <a
                  href="https://play.google.com/store/apps/details?id=com.magnusmonitors.install"
                  target="_blank"
                >
                  <img src={PlayStore} className="store-img" />
                </a>
              </div>
            </div>
            <div>
              <Link to="/apps/toolbox-for-magnus/account-delete">
                Click Here
              </Link>{" "}
              to delete your Toolbox for Magnus account.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
